import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['pandemicFindInfo1', 'pandemicFindInfo2', 'pandemicFindInfo3', 'pandemicFindInfo4', 'pandemicFindInfo5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const ImageAccordion = makeShortcode("ImageAccordion");
const GoTo = makeShortcode("GoTo");
const Paper = makeShortcode("Paper");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "finding-the-right-information"
    }}>{`Finding the right information`}</h2>
    <p>{`With so much constantly-changing information about the pandemic out there, it’s important to seek information from reliable and credible sources. Discuss with your teen where they are getting news and information from, and how trustworthy these sources are. `}<b>{`How can you tell whether information is reliable and trustworthy? Click the numbers below to see some things to consider.`}</b></p>
    <p>{`Here’s an anatomy of a reliable news source.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/9-reliable-news.png" alt="Reliable News" mdxType="SingleImage" />
    <ImageAccordion id="pandemicFindInfo1" index="1" title="Where is it published?" description={<div>
      Check the website details to see if the site is published by a reputable organisation. Domains that include .gov
      (government) or .edu (educational/academic) typically provide reliable information.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicFindInfo2" index="2" title="Who is the author?" description={<div>
      Credible sources will publish the author details. Is the author qualified to provide this information? Do they
      indicate their sources? You might even want to do your own online search to find out more about the author.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicFindInfo3" index="3" title="Does it present fact or opinion?" description={<div>
      Look for sources that present verifiable facts, rather than someone’s opinion. Factual information should provide
      sources/references, and should be verifiable by searching yourself.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicFindInfo4" index="4" title="Do other credible sources agree?" description={<div>
      Consider doing your own search of the topic and compare information with trusted sources such as government
      websites and the World Health Organisation. Domains that include .gov (government) or .edu (educational/academic)
      typically provide reliable information.
    </div>} hasNumber={true} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicFindInfo5" index="5" title="When was it published?" description={<div>A reliable source will include a publication date. Is it recent? Is the website updated regularly.</div>} hasNumber={true} mdxType="ImageAccordion" />
    <p>{`You can find a list of credible sources about COVID-19 on our `}<GoTo to="/resources" mdxType="GoTo">{`Useful Resources`}</GoTo>{` page.`}</p>
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  Does your teen have a learning difficulty, chronic health condition, or disability? Here are some extra tips:
  <br />
  <ul>
    <li>
      Filter the information you give your teen based on what you think is appropriate for them, including language they
      will understand, and how the pandemic may affect them and their health condition.
    </li>
    <li>
      Consider asking your teen’s health professional to help you develop ways to speak with your teen about the
      pandemic.
    </li>
  </ul>
    </Paper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      